<template>
  <div class="page-container">
    <!--考卷设置-->
    <el-card class="box-card">
      <div class="container">
        <el-form class="flex flex-start flex-wrap">
          <el-form-item label="编号" v-if="paper.id">
            <span>{{ paper.id }}</span>
          </el-form-item>
          <el-form-item label="名称" style="margin-right: 15px;text-align: center">
            <span><el-input v-model.trim="paper.name" style="width: 300px"></el-input></span>
          </el-form-item>
          <el-form-item label="实验" style="margin-right: 15px;text-align: center;">
            <el-select v-model="paper.experimentId" placeholder="请选择实验" style="width: 250px;"
                       @change="" :disabled="asEdit||isClickAdd">
              <el-option v-for="(item,index) in filter.experimentOption" :value="item.value" :key="index"
                         :label="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="及格分数" style="margin-right: 15px;text-align: center" v-if="paper.totalScore">
            <span><el-input v-model.number="paper.passScore" type="number" style="width: 80px"></el-input></span>
          </el-form-item>
          <el-form-item label="考卷总分" style="margin-right: 15px;text-align: center" v-if="paper.totalScore">
            <span> {{ paper.totalScore }}</span>
          </el-form-item>
          <el-form-item label="备注信息" style="margin-right: 15px;text-align: center">
            <span><el-input v-model="paper.remark" style="width: 300px"></el-input></span>
          </el-form-item>
          <el-form-item label="" v-if="!asEdit" style="margin-right: 15px;text-align: center">
            <span style="width: 100px"><el-button type="primary" v-if="!isClickAdd"
                                                  @click="AddMethods().clickNewBtn()">生成考卷</el-button></span>
          </el-form-item>
          <el-form-item v-if="asEdit||isClickAdd" label="" style="margin-right: 15px;text-align: center">
          <span style="width: 100px;margin-left: 20px;">
              <el-button type="success" @click="AddMethods().clickRandomAddBtn()">随机加题</el-button></span>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <!--新增考卷-随机时弹出的自定义选项-->
    <el-dialog
        title="随机加题"
        :visible.sync="showSetPaperOption"
        width="900px"
        center
        v-el-drag-dialog>
      <div class="add-new-option">
        <div class="li">
          <div class="title">请选择出题的题库</div>
          <div class="box">
            <el-select v-model="setAddInfo.questionBankIds" placeholder="请选择题库" multiple
                       @change="">
              <el-option v-for="(item,index) in filter.questionBankOption" :value="item.value" :key="index"
                         :label="item.label"></el-option>
            </el-select>
          </div>
        </div>
        <div class="li">
          <div class="title">请选择需要的题型</div>
          <div class="box">
            <el-checkbox-group v-model="setAddInfo.chooseTypes">
              <el-checkbox label="Radio" value="Radio">单项选择</el-checkbox>
              <el-checkbox label="Multiple" value="Multiple">多项选择</el-checkbox>
              <el-checkbox label="Judge" value="Judge">判断题</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="li">
          <div class="title">设置各个题型的数量</div>
          <div class="box">
            <div class="q-li flex flex-between" v-if="setAddInfo.chooseTypes.indexOf('Radio')>-1">
              <span style="margin-right: 15px;width: 80px;">单项选择：</span>
              <div>题数
                <el-input-number style="width: 110px;margin-top: 5px;margin-left: 10px;" size="small"
                                 :min='0'
                                 v-model="setAddInfo.sectionInfos[0].questionTotal"
                                 label="题数"></el-input-number>
              </div>
              <div>每题分数
                <el-input-number style="width: 110px;margin-top: 5px;margin-left: 10px;"
                                 size="small" :min='0'
                                 v-model="setAddInfo.sectionInfos[0].scorePerQuestion"
                                 label="每题分数"></el-input-number>
              </div>
            </div>
            <div class="q-li flex flex-between" v-if="setAddInfo.chooseTypes.indexOf('Multiple')>-1">
              <span style="margin-right: 15px;width: 80px;">多项选择：</span>
              <div>题数
                <el-input-number style="width: 110px;margin-top: 5px;margin-left: 10px;" size="small"
                                 :min='0'
                                 v-model="setAddInfo.sectionInfos[1].questionTotal"
                                 label="题数"></el-input-number>
              </div>
              <div>每题分数
                <el-input-number style="width: 110px;margin-top: 5px;margin-left: 10px;"
                                 size="small" :min='0'
                                 v-model="setAddInfo.sectionInfos[1].scorePerQuestion"
                                 label="每题分数"></el-input-number>
              </div>
            </div>
            <div class="q-li flex flex-between" v-if="setAddInfo.chooseTypes.indexOf('Judge')>-1">
              <span style="margin-right: 15px;width: 80px;">判断题：</span>
              <div>题数
                <el-input-number style="width: 110px;margin-top: 5px;margin-left: 10px;" size="small"
                                 :min='0'
                                 v-model="setAddInfo.sectionInfos[2].questionTotal"
                                 label="题数"></el-input-number>
              </div>
              <div>每题分数
                <el-input-number style="width: 110px;margin-top: 5px;margin-left: 10px;"
                                 size="small" :min='0'
                                 v-model="setAddInfo.sectionInfos[2].scorePerQuestion"
                                 label="每题分数"></el-input-number>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="AddMethods().clickSetPaperOptionAdd()">生成试题并加入列表</el-button>
      </div>
    </el-dialog>
    <!--试题列表-->
    <div style="margin-top: 15px"
         v-if="asEdit||isClickAdd"
    >
      <div class="container">
        <!--试题类型分组-->
        <el-tabs id="tabs" v-model="chooseQuestionType" type="border-card"
                 @tab-click="(tab,event)=>ListMethods().clickListTab(tab,event)">
          <el-tab-pane label="单项选择" name="Radio">
            <!--属性设置-->
            <div class="flex flex-between">
              <el-form label-width="100px" class="flex flex-start" v-if="paper.sections[paperSectionIndex['Radio']]">
                <el-form-item label="本节描述:">
                  <span><el-input style="width: 300px"
                                  v-model="paper.sections[paperSectionIndex['Radio']].sectionName"></el-input></span>
                </el-form-item>
                <el-form-item label="每题分数:">
                  <span><el-input-number :min='1' style="width: 100px" size="small"
                                         v-model="paper.sections[paperSectionIndex['Radio']].scorePerQuestion"
                                         @change="v=>ListMethods().sectionPerScoreChange('Radio',v)"
                                         label="每题分数"></el-input-number></span>
                </el-form-item>
                <el-form-item label="总题数:">
                  <span>{{ paper.sections[paperSectionIndex['Radio']].questionTotal }}</span>
                </el-form-item>
                <el-form-item label="章节总分:">
                  <span>{{ paper.sections[paperSectionIndex['Radio']].sectionTotalScore }}</span>
                </el-form-item>
              </el-form>
              <div>
                <el-button type="success" @click="ListMethods().clickAddNewQuestion()">手动加题</el-button>
              </div>
            </div>
            <!--列表-->
            <el-table id="table-Radio" :data="paper.sections[paperSectionIndex['Radio']].sectionQuestions" border fit
                      highlight-current-row v-if="paper.sections[paperSectionIndex['Radio']]"
                      style="width: 100%;" row-key="sku">
              <el-table-column label="编号" align="center" width="100px">
                <template slot-scope="scope">
                  <span>{{ scope.row.id }}</span>
                </template>
              </el-table-column>
              <el-table-column label="问题描述" align="center">
                <template slot-scope="scope">
                  <div v-html="scope.row.content" v-if="scope.row.contentType==='html'"></div>
                  <div v-else>{{ scope.row.content }}</div>
                </template>
              </el-table-column>
              <!--渲染选项列表-->
              <el-table-column label="问题选项(蓝色为正确选项)" align="center">
                <template slot-scope="scope">
                  <div :label="item.choice" align="center" v-for="item in scope.row.options"
                       :class="item.choice===scope.row.standardAnswer?'markRight':''">
                    {{ item.choice + ':' + item.content }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="80"
                               class-name="small-padding fixed-width">
                <template slot-scope="scope">
                  <!--                  <el-button type="primary" size="mini" round-->
                  <!--                             @click="ListMethods().clickEditBtn(0,scope.row,scope.$index)">-->
                  <!--                    编辑-->
                  <!--                  </el-button>-->
                  <el-button type="danger" size="mini" round
                             @click="ListMethods().clickDelBtn('Radio',scope.row.id,scope.$index)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="多项选择" name="Multiple">
            <!--属性设置-->
            <div class="flex flex-between">
              <el-form label-width="100px" class="flex flex-start" v-if="paper.sections[paperSectionIndex['Multiple']]">
                <el-form-item label="本节描述:">
                  <span><el-input style="width: 300px"
                                  v-model="paper.sections[paperSectionIndex['Multiple']].sectionName"></el-input></span>
                </el-form-item>
                <el-form-item label="每题分数:">
                  <span><el-input-number :min='1' style="width: 100px" size="small"
                                         v-model="paper.sections[paperSectionIndex['Multiple']].scorePerQuestion"
                                         @change="v=>ListMethods().sectionPerScoreChange('Multiple',v)"
                                         label="每题分数"></el-input-number></span>
                </el-form-item>
                <el-form-item label="总题数:">
                  <span>{{ paper.sections[paperSectionIndex['Multiple']].questionTotal }}</span>
                </el-form-item>
                <el-form-item label="章节总分:">
                  <span>{{ paper.sections[paperSectionIndex['Multiple']].sectionTotalScore }}</span>
                </el-form-item>
              </el-form>
              <div>
                <el-button type="success" @click="ListMethods().clickAddNewQuestion()">手动加题</el-button>
              </div>
            </div>
            <!--列表-->
            <el-table id="table-Multiple" :data="paper.sections[paperSectionIndex['Multiple']].sectionQuestions" border
                      fit
                      highlight-current-row row-key="id"
                      style="width: 100%;" v-if="paper.sections[paperSectionIndex['Multiple']]">
              <el-table-column label="编号" align="center" width="100px">
                <template slot-scope="scope">
                  <span>{{ scope.row.id }}</span>
                </template>
              </el-table-column>
              <el-table-column label="问题描述" align="center">
                <template slot-scope="scope">
                  <div v-html="scope.row.content" v-if="scope.row.contentType==='html'"></div>
                  <div v-else>{{ scope.row.content }}</div>
                </template>
              </el-table-column>
              <!--渲染选项列表-->
              <el-table-column label="问题选项(蓝色为正确选项)" align="center">
                <template slot-scope="scope">
                  <div :label="item.choice" align="center" v-for="item in scope.row.options"
                       :class="scope.row.standardAnswers.includes(item.choice)?'markRight':''">
                    {{ item.choice + ':' + item.content }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="150"
                               class-name="small-padding fixed-width">
                <template slot-scope="scope">
                  <!--                  <el-button type="primary" size="mini" round-->
                  <!--                             @click="ListMethods().clickEditBtn(1,scope.row,scope.$index)">-->
                  <!--                    编辑-->
                  <!--                  </el-button>-->
                  <el-button type="danger" size="mini" round
                             @click="ListMethods().clickDelBtn('Multiple',scope.row.id,scope.$index)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="判断题" name="Judge">
            <!--属性设置-->
            <div class="flex flex-between">
              <el-form label-width="100px" class="flex flex-start" v-if="paper.sections[paperSectionIndex['Judge']]">
                <el-form-item label="本节描述:">
                  <span><el-input style="width: 300px"
                                  v-model="paper.sections[paperSectionIndex['Judge']].sectionName"></el-input></span>
                </el-form-item>
                <el-form-item label="每题分数:">
                  <span><el-input-number :min='1' style="width: 100px" size="small"
                                         v-model="paper.sections[paperSectionIndex['Judge']].scorePerQuestion"
                                         @change="v=>ListMethods().sectionPerScoreChange('Judge',v)"
                                         label="每题分数"></el-input-number></span>
                </el-form-item>
                <el-form-item label="总题数:">
                  <span>{{ paper.sections[paperSectionIndex['Judge']].questionTotal }}</span>
                </el-form-item>
                <el-form-item label="章节总分:">
                  <span>{{ paper.sections[paperSectionIndex['Judge']].sectionTotalScore }}</span>
                </el-form-item>
              </el-form>
              <div>
                <el-button type="success" @click="ListMethods().clickAddNewQuestion()">手动加题</el-button>
              </div>
            </div>
            <!--列表-->
            <el-table id="table-Judge" :data="paper.sections[paperSectionIndex['Judge']].sectionQuestions" border fit
                      v-if="paper.sections[paperSectionIndex['Judge']]"
                      highlight-current-row
                      style="width: 100%;">
              <el-table-column label="编号" align="center" width="100px">
                <template slot-scope="scope">
                  <span>{{ scope.row.id }}</span>
                </template>
              </el-table-column>
              <el-table-column label="问题描述" align="center">
                <template slot-scope="scope">
                  <div v-html="scope.row.content" v-if="scope.row.contentType==='html'"></div>
                  <div v-else>{{ scope.row.content }}</div>
                </template>
              </el-table-column>
              <!--渲染选项列表-->
              <el-table-column label="参考答案" align="center">
                <template slot-scope="scope">
                  {{ enums.questionJudgeAnswer[scope.row.standardAnswer] }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="150"
                               class-name="small-padding fixed-width">
                <template slot-scope="scope">
                  <!--                  <el-button type="primary" size="mini" round-->
                  <!--                             @click="ListMethods().clickEditBtn(2,scope.row,scope.$index)">-->
                  <!--                    编辑-->
                  <!--                  </el-button>-->
                  <el-button type="danger" size="mini" round
                             @click="ListMethods().clickDelBtn('Judge',scope.row.id,scope.$index)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!--底部按钮-->
    <div class="page-bottom-container">
      <!--保存按钮-->
      <el-button style="margin-left: 10px;" type="warning"
                 icon="el-icon-success" @click="clickSaveBtn" v-if="asEdit">保 存
      </el-button>
      <!--预览按钮-->
      <el-button style="margin-left: 10px;" type="warning" @click="clickViewBtn"
                 v-if="asEdit&&paper.id">预 览
      </el-button>
      <!--新增按钮-->
      <el-button style="margin-left: 10px;" type="warning"
                 icon="el-icon-success" @click="AddMethods().clickAddBtn()"
                 v-if="!asEdit&&isClickAdd">新 增 考 卷
      </el-button>
      <!--预览按钮-->
      <!--      <el-button style="margin-left: 10px;" type="success"-->
      <!--                 icon="el-icon-success" @click="">预览-->
      <!--      </el-button>-->
    </div>
    <!--试题选择弹窗-->
    <el-dialog
        title="选择试题"
        :visible.sync="chooseDialogShow"
        width="70%"
        center
        v-el-drag-dialog>
      <page-question-list ref="question-list" :as-select="true" v-if="showQuestionListComponent"
                          :experiment-id="paper.experimentId"
                          @onListSelected="list=>SelectMethods().getSelectList(list)"></page-question-list>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="SelectMethods().clickSelectSureBtn()">确认选择</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {QuestionPaperModel} from "@/model/exp/QuestionPaperModel";
import {msg_confirm, msg_err, msg_success} from '@/utils/ele_component'
import {CommonModel} from '@/model/CommonModel'
import {objectToLVArr, accMul, accAdd, find_obj_from_arr_by_id, getQuery, date_format} from '@/utils/common'
import pageQuestionList from '@/views/teacher/questionList.vue'
import elDragDialog from '@/directive/el-drag-dialog'
import {mapState} from "vuex";
import {EnumsModel} from "@/model/EnumsModel";
import {ExperimentModel} from "@/model/exp/ExperimentModel";
import {QuestionBankModel} from "@/model/exp/QuestionBankModel";
import {QuestionModel} from "@/model/exp/QuestionModel";

export default {
  name: 'teacherExamPaperEdit',
  components: {pageQuestionList},
  directives: {
    elDragDialog
  },
  computed: {
    //标记新增还是修改
    asEdit: function () {
      return this.$route.query.hasOwnProperty('paperId')
    },
    paperId: {
      get() {
        return this.$route.query['paperId']
      },
      set(v) {
        return v
      }
    },
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      EnumsModel: EnumsModel,
      // 枚举
      enums: {
        questionJudgeAnswer: EnumsModel.questionJudgeAnswer,
        questionType: EnumsModel.questionType
      },
      // 考卷详情
      paper: {
        name: "课堂考卷",
        sectionObj: {},
        sections: [
          {
            sectionType: 'Radio',
            sectionName: "单项选择",
            sectionQuestions: [],
            scorePerQuestion: 1
          },
          {
            sectionType: 'Multiple',
            sectionName: "多项选择",
            sectionQuestions: [],
            scorePerQuestion: 1
          },
          {
            sectionType: 'Judge',
            sectionName: "判断题",
            sectionQuestions: [],
            scorePerQuestion: 1
          },
        ]
      },
      startTimeOptions: {
        disabledDate(time) {
          r1
        }
      },
      paperSectionIndex: {},// sections中每项类型的排序序号
      // 筛选信息
      filter: {
        // 实验列表
        experimentOption: [],
        experimentObject: {},
        experimentOrigin: [],
        // 题库列表
        questionBankOption: [],
        questionBankObject: {},
        questionBankOrigin: [],
      },
      // 试题类型筛选
      chooseQuestionType: 'Radio',
      // tab显示顺序
      tabOrders: ['Radio', 'Multiple', 'Judge'],
      // 试题选择弹窗相关
      chooseDialogShow: false,
      showQuestionListComponent: true,
      // 新增考卷相关
      isClickAdd: false,
      showSetPaperOption: false,
      setAddInfo: {
        questionBankIds: [],
        chooseTypes: ['Radio', 'Multiple', 'Judge'],
        sectionInfos: [
          {sectionType: 'Radio', questionTotal: 1, scorePerQuestion: 1},
          {sectionType: 'Multiple', questionTotal: 1, scorePerQuestion: 1},
          {sectionType: 'Judge', questionTotal: 1, scorePerQuestion: 1},
        ]
      },
    }
  },
  activated() {
  },
  mounted() {
    if (this.asEdit) {// 编辑考卷
      this.getPaperInfo()
    } else {// 新增考卷
      this.paper.name = "课堂考卷 "+ date_format(new Date(), "yyyy-MM-dd HH:mm");
    }
    this.getFilterInfo()
  },
  methods: {
    // 获取考卷详情
    async getPaperInfo() {
      this.paperId = this.$route.query['paperId']
      let data = await QuestionPaperModel.getOne(this.paperId)
      if (data) {
        let paper = JSON.parse(JSON.stringify(data))
        // 增加考卷过期时间属性
        this.paper = this.paperDataInit(paper)
      } else {
        msg_err('未找到该考卷！')
      }
    },
    // 获取的考卷信息初始化
    paperDataInit(paper) {
      // 遍历，设置每项题型和缺失项
      // 将sections按照type存入对象中
      let hasType = []
      if(this.asEdit){// 如果是编辑模式
        paper.sections=JSON.parse(paper.sectionInfos) // 字符串信息转为json
      }
      paper.sections.forEach((li, index) => {
        if (!this.asEdit && !li.scorePerQuestion) {
          li.scorePerQuestion = 1
        }
        this.paperSectionIndex[li.sectionType] = index
        hasType.push(li.sectionType)
        if (index === 0) {
          li["sectionName"] = "单项选择题"
        }
      })
      // 补齐没有的题型信息
      let index = paper.sections.length
      this.tabOrders.forEach(li => {
        if (hasType.indexOf(li) === -1) {// 如果没有这类题型
          this.paperSectionIndex[li] = index
          paper.sections.push({
            sectionType: li,
            sectionTotalQuestion: 0,
            scorePerQuestion: 1,
            sectionQuestions: [],
          })
          index += 1
        }
      })
      if (paper.totalScore) {
        paper.passScore = Math.round(paper.totalScore * 0.6)
      }


      return paper
    },
    // 获取筛选信息
    async getFilterInfo() {
      // 获取实验列表
      let experimentList = await ExperimentModel.getTeacherExperimentList(this.userInfo);
      let generateListFilter0 = CommonModel.generateListFilterOptions('name', 'id', experimentList, false)
      this.filter.experimentOption = generateListFilter0[0]
      this.filter.experimentObject = generateListFilter0[1]
      this.filter.experimentOrigin = experimentList
      this.paper.experimentId = experimentList[0]["id"]
      // 获取题库列表
      let [questionBankList] = await QuestionBankModel.getList(1, 500, {
        schoolId: this.userInfo.schoolId,
        departmentId: this.userInfo.departmentid,
        experimentId:this.paper.experimentId,
        userId:this.userInfo.userid,
      });
      let generateListFilter1 = CommonModel.generateListFilterOptions('name', 'id', questionBankList, false)
      this.filter.questionBankOption = generateListFilter1[0]
      this.filter.questionBankObject = generateListFilter1[1]
      this.filter.questionBankOrigin = questionBankList
    },
    // 点击考卷保存按钮
    async clickSaveBtn() {
      let paper = {}
      paper = this.prePaperPost()
      if (paper) {
        if (await msg_confirm('确认要修改该考卷信息吗？')) {
          if (getQuery("paperId")) {
            paper.id = getQuery("paperId")
          }
          let data = await QuestionPaperModel.update(paper)
          if (data) {
            msg_success('修改考卷信息成功')
          }
        }
      }
    },
    // 点击考卷预览按钮
    clickViewBtn() {
      // todopaper.id 新增时预览功能
      this.$router.push({name: 'teacherExamPaper', query: {id: this.paper.id, asType: 'teacherView'}})
    },
    // 考卷提交前的数据修正
    prePaperPost() {
      let paper = JSON.parse(JSON.stringify(this.paper))
      let paper1 = JSON.parse(JSON.stringify(this.paper))
      if (this.beforeSave()) {
        if (paper.passScore && paper.passScore > paper.totalScore) {
          msg_err('请填写及格分数且及格分不能超过总分')
          return false
        }
        // 遍历,删除试题为空的 就可以提交成功了
        paper1.sections = []
        paper1.questionNumber=0;
        for (let i = 0; i < paper.sections.length; i++) {
          paper1.questionNumber+=paper.sections[i].sectionQuestions.length;
          if (paper.sections[i].sectionQuestions.length > 0) {
            // 判断是否有描述
            if (!paper.sections[i].sectionName) {
              console.log("题型英文", paper.sections[i].sectionType)
              msg_err(this.enums.questionType[paper.sections[i].sectionType] + ' 题型没有填写本节描述文字')
              return false
            }
            paper.sections[i]["questionIds"]=[]
            paper.sections[i].sectionQuestions.forEach(q=>{
              paper.sections[i]["questionIds"].push(q.id)
            })
            paper.sections[i].sectionQuestions=[]
            paper1.sections.push(paper.sections[i])
          }
        }
        paper1.sectionInfos=JSON.stringify(paper1.sections)
        if(!this.asEdit){ // 如果不是编辑模式
          paper1.creatorId=this.userInfo.userid;// 记录创建人id
        }
        return paper1
      } else {
        return false
      }
    },
    // 试题列表Methods
    ListMethods() {
      let $this = this
      return {
        // 点击tab切换
        clickListTab(tab, event) {
        },
        // 点击试题列表的删除按钮
        async clickDelBtn(index, id, $index) {
          index = $this.paperSectionIndex[index]
          if (await msg_confirm('确定要从考卷中删除该题吗？')) {
            $this.paper.sections[index].sectionQuestions.splice($index, 1)
            this.calScore(index)
          }
        },
        // 计算试题章节和总的总分
        calScore(index) {
          console.log(index)
          // 计算本节试题总数和分数
          let scorePerQuestion = $this.paper.sections[index].scorePerQuestion
          let questionTotal = $this.paper.sections[index].sectionQuestions.length
          let sectionTotalScore = accMul(scorePerQuestion, questionTotal)
          $this.$set($this.paper.sections[index], 'questionTotal', questionTotal)
          $this.$set($this.paper.sections[index], 'sectionTotalScore', sectionTotalScore)
          /** 计算本考卷总分 **/
              // 遍历
          let totalScore = 0
          $this.paper.sections.forEach(li => {
            if (li.sectionTotalScore) {
              totalScore = accAdd(totalScore, li.sectionTotalScore)
            }
          })
          $this.$set($this.paper, 'totalScore', totalScore)
          $this.$set($this.paper, 'passScore', Math.round(totalScore * 0.6))
        },
        // 每章节小题分数被改变
        sectionPerScoreChange(index, v) {
          index = $this.paperSectionIndex[index]
          this.calScore(index)
        },
        // 点击新增试题按钮
        clickAddNewQuestion() {
          $this.chooseDialogShow = true
          $this.$nextTick(() => {
            $this.showQuestionListComponent = true
          })
        },
      }
    },
    // 选择框Methods
    SelectMethods() {
      let $this = this
      return {
        // 点击选择试题弹窗的确定按钮
        clickSelectSureBtn() {
          $this.$refs['question-list'].ListMethods().parentSureSelect()
          $this.chooseDialogShow = false
        },
        // 获取子组件传回的选择好的列表
        getSelectList(list) {
          console.log("已选择题目列表如下")
          console.log(list)
          // list 格式[{Radio问题列表},{Multiple问题列表},{},{}]
          // 遍历添加到对应类型的问题列表中
          for (let i in list) {
            if (list.hasOwnProperty(i)) {
              let li = list[i]
              let index = $this.paperSectionIndex[i]
              li.forEach(question => {
                // 判断是否有同样的题 低效方法每次遍历 todo 维护一个section和questionId的数组
                let hasTag = false
                for (let j = 0; j < $this.paper.sections[index].sectionQuestions.length; j++) {
                  if ($this.paper.sections[index].sectionQuestions[j].id === question.id) {
                    hasTag = true
                    break
                  }
                }
                if (!hasTag) {
                  $this.paper.sections[index].questionTotal += 1// 修改每个类型的问题总数
                  $this.paper.sections[index].sectionQuestions.push(question)
                }
                // alert('here')
                $this.ListMethods().calScore($this.paperSectionIndex[i])
              })
            }
          }

          // 重绘问题列表组件
          $this.$nextTick(() => {
            $this.showQuestionListComponent = false
          })
        }
      }
    },
    // 考卷保存和新增前的参数检测
    beforeSave() {
      if (!this.paper.name) {
        msg_err('请先填写名称!')
        return false
      }
      if (!this.paper.experimentId) {
        msg_err('请先选择科目!')
        return false
      }
      if (!this.paper.passScore) {
        msg_err('请先填写及格分数!')
        return false
      }
      if (!this.paper.totalScore) {
        msg_err('请先填写或计算总分!')
        return false
      }
      return true
    },
    // 新增考卷Methods
    AddMethods() {
      let $this = this
      return {
        clickNewBtn() {
          // 生成新的空对象
          if (!$this.paper.hasOwnProperty('experimentId')) {
            msg_err('请先选择实验!')
            return false
          }
          $this.isClickAdd = true
          $this.paper = $this.paperDataInit($this.paper)
        },
        // 点击随机加题按钮
        clickRandomAddBtn() {
          $this.showSetPaperOption = true
        },
        // 点击新增考卷属性弹出的新增按钮
        async clickSetPaperOptionAdd() {
          let queryArray = []
          // 显示参数
          let chooseSectionList = []
          for (let i = 0; i < $this.setAddInfo.chooseTypes.length; i++) {// 遍历
            let chooseType = $this.setAddInfo.chooseTypes[i]
            if ($this.tabOrders.indexOf(chooseType) > -1) {
              chooseSectionList.push($this.setAddInfo.sectionInfos[$this.tabOrders.indexOf(chooseType)])
            }
          }
          for (let i = 0; i < chooseSectionList.length; i++) {
            let li = chooseSectionList[i];
            if (!li.questionTotal) {
              msg_err($this.enums.questionType[li.sectionType] + ' 需要输入总题数')
              return false
            }
            if (!li.scorePerQuestion) {
              msg_err($this.enums.questionType[li.sectionType] + ' 需要输每题分数')
              return false
            }
          }
          if ($this.setAddInfo.questionBankIds.length === 0) {
            msg_err("请选择出题的题库！")
            return false
          }
          // 生成随机试题列表
          let data = await QuestionModel.getRandomQuestionList($this.setAddInfo)
          if (data) {
            // 编辑，加入题目到对应的小节
            data.forEach(li => {
              let sectionIndex=0;
              let sectionName="";
              if (li["sectionType"] === "Radio") {
                sectionIndex=0
                sectionName="单选题"
              }
              if (li["sectionType"] === "Multiple") {
                sectionIndex=1
                sectionName="多选题"
              }
              if (li["sectionType"] === "Judge") {
                sectionIndex=2
                sectionName="判断选题"
              }
              let existNumber=0;
              li["questionList"].forEach(q => {
                let [exist] = find_obj_from_arr_by_id("id", q.id, $this.paper.sections[sectionIndex].sectionQuestions)
                if (exist === -1) {// 不存在相同id题目
                  $this.paper.sections[sectionIndex].sectionQuestions.push(q)
                }else{
                  existNumber++
                }
              })
              //msg_confirm(`共生成了${li["questionList"].length}个${sectionName}，已跳过${existNumber}个已存在题目。`,"添加试题成功")
              $this.ListMethods().calScore(sectionIndex);
            })

            $this.showSetPaperOption = false
            $this.paper = $this.paperDataInit($this.paper)
          }
        },
        // 点击新增按钮
        async clickAddBtn() {
          if (await msg_confirm('确定要新增该考卷吗？')) {
            let paper = $this.prePaperPost()
            console.log(paper)
            if (paper) {
              // 根据考卷类型筛选
              let data = await QuestionPaperModel.save(paper)
              if (data) {
                msg_success('新增考卷成功')
                $this.$router.push("/teacher/exam")
              }
            }
          }

        },
      }
    }
  }
}
</script>

<style lang="less" scoped>
.markRight {
  color: #2bb7ff;
}

.page-bottom-container {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.add-new-option {
  .li {
    margin-bottom: 20px;

    .title {
      margin-bottom: 10px;
      font-size: 16px;
    }

    .box {
      .q-li {
        margin-bottom: 10px;

        > div {
          text-align: center;
        }
      }
    }
  }
}

// tabs排序
.tabs-order {
  color: #555;
  margin-bottom: 15px;

  .li {
    margin-right: 20px;
    font-size: 15px;
    color: #666;
    cursor: move;
  }

  .li:not(:first-child) {
    border-left: none;
  }
}
</style>
